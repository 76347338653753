/* Custom Scrollbar Styles */
.scroll::-webkit-scrollbar {
    width: 6px; /* Width of the scrollbar */
    border-radius: 10px;
  }
  
  .scroll::-webkit-scrollbar-thumb {
    background-color: #b5b5b5; /* Color of the scrollbar thumb */
    border-radius: 10px; /* Rounded corners of the thumb */
    
  }
  
  .scroll::-webkit-scrollbar-track {
    background-color: #f1f1f1; /* Color of the scrollbar track */
  }
  
  .scroll::-webkit-scrollbar-thumb:hover {
    background-color: #808080; /* Color of the thumb on hover */
    transition: 1s ease-in-out;
  }
