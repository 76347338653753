.time-slot-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.slot-item {
  box-sizing: border-box;
  width: 100%;
  border: 1px solid transparent;
  border-radius: 10px;
  padding: 0.5rem;
  padding-left: 1rem;
  margin: 8px 0;
  display: flex;
  align-items: center;
  justify-self: center;
  align-self: center;
  transition: all 0.2s ease-in-out;
  border-color: #000;
}

/* .selected {
  background-color: rgba(0, 0, 0, 0.1);
} */

@media (max-width: 480px) {
  .time-slot-container {
    width: 100%;
  }
}