/* SplashScreen.css */

.splash-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  transition: opacity 1s ease-in-out;
  z-index: 2;
  /* Set a high z-index to make it appear over other content */
}

.splash-screen img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.splash-screen.hidden {
  opacity: 0;
  z-index: -1;
}
